import { apiConstants } from '../common/apiConst';
import { request } from '../services/request';
import { projectService } from '../services/project.service.js';
import { spatialService } from '../services/spatial.services.js';
import { commonService } from '../services/common.service.js';
import { spatialFunctions } from './spatialFunctions';
import { CUSTOM_DATE_FILTERS, dateFunctions } from './dateFunctions';
import store from '../store';
import { emergencyManagement } from '../store/emergencyManagement.module.js';
import { gridFilters } from '../store/gridFilters.module';
import axios from 'axios';
import { latLngBounds } from 'leaflet';

export const mapLayerFunctions = {
    getSpatialLayers,
    getLineIcon,
    getPolygonIcon,
    getWMSExtent,
    getContoursExtent,
    makeIdentifyRequest,
    dateFilterWMSLayers,
    processLayer,
    getAuthkeys,
};

// want to return the icon that we can use in the legend for the car layers

function distinct(value, index, self) {
    return self.indexOf(value) === index;
}

// creates an array of children using the child's parentID
function getChildren(parentID, tree) {
    var children = [];
    tree.forEach((branch) => {
        if (branch.parentID == parentID) {
            children.push(branch.id);
        }
    });
    return children;
}

function dateFilterWMSLayers() {
    let appliedWMSFilter = false;
    if (
        store.state.gridFilters.groupedTables &&
        Object.keys(store.state.gridFilters.groupedTables).length > 0
    ) {
        store.state.gridFilters.selectedFilters.forEach((filter) => {
            if (
                !appliedWMSFilter &&
                Object.keys(store.state.gridFilters.groupedTables).includes(
                    filter.dataSetId.toString()
                ) &&
                filter.selectedFilters[0].specialPredicate != 'Alltime' &&
                CUSTOM_DATE_FILTERS.includes(
                    filter.selectedFilters[0].specialPredicate
                )
            ) {
                let filterTime = dateFunctions.calcDataManagerDateFilter(
                    filter.selectedFilters[0].specialPredicate,
                    true
                );
                store.dispatch(
                    'emergencyManagement/applyQuickTimeFilter',
                    filterTime
                );
                appliedWMSFilter = true;
            }
        });
    }
}

function formatWMSLayers(wmsLayers, authkeys, projectIds, options) {
    let layersStruct = [];
    let erLayerIDs = [];
    wmsLayers.forEach((wmsLayer) => {
        if (
            wmsLayer.WMSName.includes('vw_ER') &&
            !wmsLayer.WMSName.toLowerCase().includes('emergencyresponse') &&
            !wmsLayer.WMSName.toLowerCase().includes('ics')
        ) {
            erLayerIDs.push(wmsLayer.WMSLayerId);
        }
        const workspace = wmsLayer.Workspace;
        let layer = {
            id: wmsLayer.WMSLayerId,
            url: wmsLayer.WMSUrl,
            name: wmsLayer.WMSLabel,
            isChecked: wmsLayer.IsVisible,
            format: wmsLayer.WMSFormat,
            layers: wmsLayer.WMSName,
            styles: wmsLayer.Style,
            transparent: true,
            attribution: '',
            options: {
                maxZoom: wmsLayer.MaxZoom,
                maxNativeZoom: wmsLayer.MaxZoom,
                editTime: 0,
            },
            legend:
                wmsLayer.WMSUrl == ''
                    ? ''
                    : wmsLayer.WMSUrl +
                      '?REQUEST=GetLegendGraphic&VERSION=1.1.1&STYLE=' +
                      wmsLayer.Style +
                      '&FORMAT=image/png&Layer=' +
                      wmsLayer.WMSName,
            hasChild: wmsLayer.WMSUrl == '',
            isExpanded: false,
            mapLayerID: wmsLayer.MapLayerID,
            tileSize: wmsLayer.TileSize,
            externalCache: wmsLayer.ExternalCache,
            internalCache: wmsLayer.InternalCache,
            minZoom: wmsLayer.MinZoom,
            maxZoom: wmsLayer.MaxZoom,
            zIndex: wmsLayer.DrawOrder || 999999,
            toc_groupOrder: wmsLayer.toc_groupOrder,
            toc_layerOrder: wmsLayer.toc_layerOrder,
        };

        // if we have an authkey for this workspace, apply it to the layer definition
        if (workspace !== null && authkeys.AuthKeys[workspace] !== undefined) {
            layer.options.authkey = authkeys.AuthKeys[workspace];
            layer.legend += '&authkey=' + authkeys.AuthKeys[workspace];
        } else if (wmsLayer.authkey !== '') {
            layer.options.authkey = wmsLayer.authkey;
            layer.legend += '&authkey=' + wmsLayer.authkey;
        }

        if (wmsLayer.BranchID !== undefined) {
            layer.parentID = wmsLayer.BranchID;
        }
        if (wmsLayer.query !== undefined) {
            layer.options.CQL_Filter = wmsLayer.query;
        } else {
            layer.options.CQL_Filter = '';
        }
        if (wmsLayer.WMSName.includes(':vw_ER_')) {
            layer.legend += '&CQL_Filter=ProjectID in (' + projectIds + ')';
        }
        if (wmsLayer.WMSName.includes('dreams_dev:vw_risk_model')) {
            layer.legend +=
                "&CQL_Filter=proj_id in ('" +
                projectIds +
                "') and version = " +
                options.ver;

            layer.options.CQL_Filter =
                "proj_id in ('" +
                projectIds +
                "') and version = " +
                options.ver;

            if (options.ver !== 1 && options.user !== '') {
                layer.legend += " and user_id = '" + options.user + "' ";
                layer.options.CQL_Filter +=
                    " and user_id = '" + options.user + "' ";
            }
        } else if (
            wmsLayer.WMSName.includes('dreams_dev:vw_linksstyle') ||
            wmsLayer.WMSName.includes('dreams_dev:vw_subcatchments')
        ) {
            layer.legend += "&CQL_Filter=model in ('model_a') ";
            layer.options.CQL_Filter = "model in ('model_a') ";
        }
        layersStruct.push(layer);
    });
    layersStruct = layersStruct.filter(distinct);
    layersStruct.forEach((branch) => {
        branch['children'] = getChildren(branch.id, layersStruct);
    });
    var newLayersStruct = {};
    layersStruct.forEach((branch) => {
        newLayersStruct[branch.id] = branch;
    });
    store.commit('emergencyManagement/setERLayerIDs', erLayerIDs);
    store.dispatch('emergencyManagement/updateLayersStruct', newLayersStruct);
    dateFilterWMSLayers();
    return true;
}

async function getWMSExtent(layername) {
    projectService.GetWMSExtent(layername).then((res) => {
        let minX = 180;
        let minY = 90;
        let maxX = -180;
        let maxY = -90;
        var coords = [];
        var coordStrs = res.data.slice(10, -2).split(', ');
        coordStrs.forEach((coordStr) => {
            coords.push(coordStr.split(' '));
        });
        coords.forEach((point) => {
            let xCoord = point[0];
            let yCoord = point[1];
            if (!isNaN(xCoord) && !isNaN(yCoord)) {
                minX = Math.min(
                    minX,
                    spatialFunctions.meters2lng(parseFloat(xCoord))
                );
                maxX = Math.max(
                    maxX,
                    spatialFunctions.meters2lng(parseFloat(xCoord))
                );
                minY = Math.min(
                    minY,
                    spatialFunctions.meters2lat(parseFloat(yCoord))
                );
                maxY = Math.max(
                    maxY,
                    spatialFunctions.meters2lat(parseFloat(yCoord))
                );
            }
        });

        let xRange = maxX - minX;
        let yRange = maxY - minY;

        let zoomCushion = 0.1; // zoomCushion*100 is the %buffer
        return {
            minX: parseFloat(
                spatialFunctions.correctLongitude(minX - xRange * zoomCushion)
            ),
            minY: parseFloat(
                spatialFunctions.correctLatitude(minY - yRange * zoomCushion)
            ),
            maxX: parseFloat(
                spatialFunctions.correctLongitude(maxX + xRange * zoomCushion)
            ),
            maxY: parseFloat(
                spatialFunctions.correctLatitude(maxY + yRange * zoomCushion)
            ),
        };
    });
}

function getContoursExtent(jobId, callback = null) {
    projectService.GetContoursExtent(jobId).then((res) => {
        // POLYGON ((-80 48, -79 48, -79 49, -80 49, -80 48))
        // POLYGON ((BL, BR, TR, TL, BL))
        let resGeom = res.data;
        if (resGeom === null || resGeom === '') {
            return null;
        }
        let coordinates = resGeom
            .split('POLYGON ((')[1]
            .split('))')[0]
            .split(', ')
            .map((coord) => {
                return coord.split(' ').map((num) => parseFloat(num));
            });
        let minX = spatialFunctions.meters2lng(coordinates[0][0]);
        let minY = spatialFunctions.meters2lat(coordinates[0][1]);
        let maxX = spatialFunctions.meters2lng(coordinates[2][0]);
        let maxY = spatialFunctions.meters2lat(coordinates[2][1]);

        let bounds = latLngBounds([
            { lat: minY, lng: minX },
            { lat: maxY, lng: maxX },
        ]);
        if (callback) {
            callback(bounds);
        } else {
            return bounds;
        }
    });
}

function styleProjectLayer(lyr) {
    let projStyling = {
        FontColor: lyr.FontColour,
        FontRotation: lyr.FontRotation,
        FontSize: lyr.FontSize,
        FontStyle: lyr.FontStyle,
        FontTransparency: lyr.FontTransparency,
        FontType: lyr.FontType,
        LineColor: lyr.LineColour,
        LineStyle: lyr.LineStyle,
        LineWidth: lyr.LineWidth,
        PolygonColor: lyr.PolygonColour,
        PolygonStyle: lyr.PolygonStyle,
        PolygonTransparency: lyr.PolygonTransparency,
        PolygonBorderColor: lyr.PolygonBorderColour,
        PolygonWidth: lyr.PolygonWidth,
        Symbology: lyr.Symbology,
        SymbologyColor: lyr.SymbologyColour,
        PointOutlineColor: lyr.PointOutlineColour,
    };
    lyr.Geometry.features.forEach((geom) => {
        geom.styling = {};
        geom.styling = projStyling;
    });
    return lyr;
}

function getLineIcon(colour, width, dashed = false, opacity = 1) {
    return (
        '<svg height="13px" width="13px"><line x1="0" y1="13" x2="13" y2="0" style="stroke-width:' +
        (width + 1.5) +
        ';fill:' +
        colour +
        ';stroke:' +
        colour +
        ';stroke-dasharray:' +
        (dashed ? '5,3' : 'none') +
        ';stroke-opacity:' +
        opacity +
        '"/></svg>'
    );
}

function getPolygonIcon(fillColour, borderColour, borderWidth) {
    return (
        '<svg height="13" width="13"><polygon points="0,0 0,13 13,13 13,0" style="fill:' +
        fillColour +
        ';stroke:' +
        borderColour +
        ';stroke-width:' +
        borderWidth +
        '" /></svg>'
    );
}

async function getAuthkeys(projectIds) {
    const authkeys = await projectService.getGeoserverAuthkeys(projectIds);
    store.dispatch(
        'emergencyManagement/updateAuthkeys',
        authkeys.data.AuthKeys
    );
    store.commit('projects/setAuthKeys', authkeys.data.AuthKeys);
    return authkeys.data;
}

async function getSpatialLayers(projectIds, options = { ver: 1, user: '' }) {
    try {
        const authkeys = await getAuthkeys(projectIds);
        const res = await projectService.getProjectLayersList(projectIds);
        const projectDataLayers = res.data;
        let allWmsLayers = [];
        for (const proj of projectDataLayers) {
            if (store.getters['projects/ProjectID'] == proj.ProjectID) {
                const restructuredProjectLayers = {};
                proj.ProjectLayers.forEach((element) => {
                    restructuredProjectLayers[element.DatasetID] = element;
                });
                proj.ProjectLayers = restructuredProjectLayers;

                if (
                    (!proj.ProjectLayers || proj.ProjectLayers.length === 0) &&
                    (!proj.WMSLayer || proj.WMSLayer.length === 0)
                ) {
                    const emptyLayers = [
                        {
                            DisplayName: 'No layers defined for this project',
                            Geometry: {
                                features: [],
                                type: 'FeatureCollection',
                                visible: true,
                            },
                        },
                    ];
                    proj.ProjectLayers = emptyLayers;
                } else if (proj.WMSLayer) {
                    allWmsLayers.push(...proj.WMSLayer);
                }
                proj.projectDataLayers = proj.ProjectLayers;
            }
        }
        formatWMSLayers(allWmsLayers, authkeys, projectIds, options);
        await store.commit(
            'projectLayers/addProjectLayerData',
            projectDataLayers
        );
        // Process layers with DisplayOption true
        store.state.projectLayers.projectLayersReady = false;

        for (const proj of projectDataLayers) {
            if (Object.keys(proj).includes('ProjectLayers')) {
                await Promise.all(
                    Object.values(proj.ProjectLayers).map(async (layer) => {
                        store.state.projectLayers.projectLayerAdded = false;
                        layer.visible = layer.DisplayOption;
                        if (layer.DisplayOption) {
                            await processLayer(layer, projectIds);
                        }
                    })
                );
            }
        }
        store.state.projectLayers.projectLayersReady = true;
    } catch (error) {
        console.error(error);
    }
}

async function processLayer(layer, projectID) {
    try {
        if (layer.DatasetID > 0) {
            const res = await projectService.getProjectLayerById(
                projectID,
                layer.DatasetID
            );
            if (res.data.IsError) {
                throw new Error(res.data.Message);
            }
            const updatedLayer = res.data;
            layer.Geometry = updatedLayer.Geometry;
        }

        let dataSource = layer.Geometry;
        layer.geomDataLoaded = layer.Geometry.visible;

        if (layer.Geometry.features.length > 0) {
            layer.ShowLabels = layer.Geometry.features[0].properties[0].tooltip;
            dataSource.labelVisibleIndex = 0;
            if (!dataSource.labelVisibleIndex) {
                for (
                    let i = 0;
                    i < dataSource.features[0].properties.length;
                    i++
                ) {
                    if (dataSource.features[0].properties[i].tooltip) {
                        dataSource.labelVisibleIndex = i;
                    }
                }
            }
        }
        if (
            layer.Geometry.features.length &&
            layer.Geometry.features[0].geometry.type == 'Point'
        ) {
            layer.Clustered = true;
        } else {
            layer.Clustered = false;
        }

        layer = styleProjectLayer(layer);

        await store.commit('projectLayers/updateProjectLayerData', {
            layer,
            projectID,
        });
        await store.commit('projectLayers/setProjectLyrs', {
            layer,
            projectID,
        });
        store.state.projectLayers.projectLayerAdded = true;

        return layer;
    } catch (error) {
        console.error(error);
        layer.isFetchError = true;
        return layer; // Return the original layer in case of an error
    }
}

export function getLayerGeometryType(layer) {
    return layer.Geometry.features[0].geometry.type;
}

async function getDocumentsAndPhotos(spatialServiceReturn, i, j) {
    if (spatialServiceReturn.data[i].Geometry[j].documents) {
        for (
            let docIndex = 0;
            docIndex <
            spatialServiceReturn.data[i].Geometry[j].documents.length;
            docIndex++
        ) {
            if (
                spatialServiceReturn.data[i].Geometry[j].documents[
                    docIndex
                ].FileName.toLowerCase().indexOf('png') > 0 ||
                spatialServiceReturn.data[i].Geometry[j].documents[
                    docIndex
                ].FileName.toLowerCase().indexOf('jpg') > 0 ||
                spatialServiceReturn.data[i].Geometry[j].documents[
                    docIndex
                ].FileName.toLowerCase().indexOf('jpeg') > 0
            ) {
                spatialServiceReturn.data[i].Geometry[j].documents[
                    docIndex
                ].src =
                    'data:image/jpg; base64, iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MEVBMTczNDg3QzA5MTFFNjk3ODM5NjQyRjE2RjA3QTkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MEVBMTczNDk3QzA5MTFFNjk3ODM5NjQyRjE2RjA3QTkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowRUExNzM0NjdDMDkxMUU2OTc4Mzk2NDJGMTZGMDdBOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowRUExNzM0NzdDMDkxMUU2OTc4Mzk2NDJGMTZGMDdBOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjjUmssAAAGASURBVHjatJaxTsMwEIbpIzDA6FaMMPYJkDKzVYU+QFeEGPIKfYU8AETkCYI6wANkZQwIKRNDB1hA0Jrf0rk6WXZ8BvWkb4kv99vn89kDrfVexBSYgVNwDA7AN+jAK3gEd+AlGMGIBFDgFvzouK3JV/lihQTOwLtOtw9wIRG5pJn91Tbgqk9kSk7GViADrTD4HCyZ0NQnomi51sb0fUyCMQEbp2WpU67IjfNjwcYyoUDhjJVcZBjYBy40j4wXgaobWoe8Z6Y80CJBwFpunepIzt2AUgFjtXXshNXjVmMh+K+zzp/CMs0CqeuzrxSRpbOKfdCkiMTS1VBQ41uxMyQR2qbrXiiwYN3ACh1FDmsdK2Eu4J6Tlo31dYVtCY88h5ELZIJJ+IRMzBHfyJINrigNkt5VsRiub9nXICdsYyVd2NcVvA3ScE5t2rb5JuEeyZnAhmLt9NK63vX1O5Pe8XaPSuGq1uTrfUgMEp9EJ+CQvr+BJ/AAKvAcCiAR+bf9CjAAluzmdX4AEIIAAAAASUVORK5CYII=';
                let downloadDocumentByIDReturn = await commonService.downloadThumbnailByID(
                    spatialServiceReturn.data[i].Geometry[j].documents[docIndex]
                        .DocumentId,
                    spatialServiceReturn.data[i].Geometry[j].documents[docIndex]
                        .FileName,
                    'image'
                );
                spatialServiceReturn.data[i].Geometry[j].documents[
                    docIndex
                ].src = 'data:image/png; base64, ' + downloadDocumentByIDReturn;
            }
        }
        //sort documents array so photos show up first, then documents
        spatialServiceReturn.data[i].Geometry[j].documents.sort((a, b) => {
            if (a.src) {
                return -1;
            }
            if (b.src) {
                return 1;
            }
            return 0;
        });
    }
}
async function getNSDocumentsAndPhotos(
    spatialServiceReturn,
    layerDescription,
    i,
    j,
    NSToken
) {
    let NSFeatureServiceLayerBaseUrl =
        'https://nsfederated.gis.nscorp.com/arcgis/rest/services/SafetyEnvironmental/SafetyEnvironmental_RO/FeatureServer';

    //convert geometry.properties label/value array to an object
    let geomObj = {};
    spatialServiceReturn.data[i].Geometry[j].properties.forEach((prop) => {
        geomObj[prop.label] = prop.value;
    });
    //need reference from Adapt layer ID to NS feature service layer ID, but it is static
    // properties[0] is the Description, which is 'NS Asset - <ns map service layer number>'
    let NSFeatureServiceLayerID = parseInt(layerDescription.match(/\d+/)[0]);
    // properties[0] is the NS feature OBJECTID
    geomObj.OBJECTID =
        spatialServiceReturn.data[i].Geometry[j].properties[0].value;
    //call NS feature service with token, layer ID, current geometry's OBJECTID to query if any attachments are present, and if so, push to documents array
    let NSFeatureServiceReturn = await axios.get(
        `${NSFeatureServiceLayerBaseUrl}/${NSFeatureServiceLayerID}/${geomObj.FeatureID}/attachments/?token=${NSToken}&f=json`,
        {
            transformRequest: (data, headers) => {
                delete headers.common;
            },
        }
    );
    NSFeatureServiceReturn.data.attachmentInfos.forEach((item) => {
        spatialServiceReturn.data[i].Geometry[j].documents.push({
            name: item.name,
            src: `${NSFeatureServiceLayerBaseUrl}/${NSFeatureServiceLayerID}/${geomObj.FeatureID}/attachments/${item.id}?token=${NSToken}`,
        });
    });
}

export async function makeIdentifyRequest(identifyPayload, ClientToken = null) {
    let spatialServiceReturn;
    try {
        spatialServiceReturn = await spatialService.mapLayers(identifyPayload);
    } catch (error) {
        // if this is a 500, it is likely that there is an incorrect
        //  table location in tbl_MapLayers
        console.log('Error querying layers');
        return;
    }
    if (spatialServiceReturn.status == 200) {
        let layerDescription = '';
        for (let i = 0; i < spatialServiceReturn.data.length; i++) {
            for (
                let j = 0;
                j < spatialServiceReturn.data[i].Geometry.length;
                j++
            ) {
                //need new logic to decide if this geometry/layer is NS asset
                spatialServiceReturn.data[i].Geometry[j].properties.forEach(
                    (property) => {
                        if (property.label == 'LayerDescription') {
                            layerDescription = property.value;
                        }
                    }
                );
                if (!layerDescription.startsWith('NS Asset')) {
                    //handle non-NS-Asset layer photos/documents
                    await getDocumentsAndPhotos(spatialServiceReturn, i, j);
                } else {
                    //handle NS asset layer photos/documents
                    getNSDocumentsAndPhotos(
                        spatialServiceReturn,
                        layerDescription,
                        i,
                        j,
                        ClientToken
                    );
                }
            }
        }
        return spatialServiceReturn.data;
    }
}
