<!-- 
    * when adding a new component, please add the componentName to toolsInfo[] in oneMapTools.js utils
 -->
<template>
    <b-col
        xs="auto"
        sm="auto"
        md="auto"
        lg="auto"
        xl="auto"
        class="no-padding no-margin"
    >
        <b-container class="frame-container" fluid="true">
            <b-row class="d-flex viewer-row no-padding no-margin">
                <b-col
                    v-if="side == 'left'"
                    id="buttonbar-left"
                    class="frame no-padding frame-buttonbar left"
                    :class="'taskbar-buttons_' + side"
                >
                    <draggable-tools
                        @start="drag = true"
                        @end="drag = false"
                        v-model="taskbarButtons"
                        draggable=".item"
                        group="items"
                        @change="updateButtonsArr(side)"
                    >
                        <button
                            v-for="item in taskbarButtons"
                            :key="item.ToolID"
                            @click="taskbarButtonClick(item, side)"
                            :class="
                                projectOptionClass(
                                    item.ProjectDependent,
                                    item.ProjectList
                                )
                            "
                            :id="item.ToolID"
                            :disabled="
                                item.ProjectDependent &&
                                    projectNotSelected(item.ProjectList)
                            "
                            v-show="
                                !(
                                    item.ProjectDependent &&
                                    item.ProjectList === ''
                                )
                            "
                        >
                            <img
                                class="pb-1"
                                width="26"
                                :src="toolIconPath(item.ToolName)"
                            />
                            {{ item.ToolName }}
                        </button>
                    </draggable-tools>
                </b-col>
                <!-- <resize-bar
                    v-if="side == 'right'"
                    :show-bar="displayedTool !== ''"
                    @updateWidth="updateWidth"
                ></resize-bar> -->
                <b-col
                    :id="'sidebar-' + side"
                    class="frame frame-sidebar no-padding"
                    :style="dynamicSidebarWidth"
                    v-show="displayedTool !== ''"
                >
                    <component
                        v-bind:is="componentName"
                        v-bind="currentProperties"
                        @close="close()"
                        @openProjects="$emit('openProjects')"
                        @navigateToDataTool="navigateToDataTool()"
                    />
                </b-col>
                <!-- <resize-bar
                    v-if="side == 'left'"
                    :show-bar="displayedTool !== ''"
                    @updateWidth="updateWidth"
                ></resize-bar> -->
                <b-col
                    v-if="side == 'right'"
                    cols="2"
                    id="buttonbar-right"
                    class="frame no-padding frame-buttonbar right"
                    :class="'taskbar-buttons_' + side"
                >
                    <draggable-tools
                        @start="drag = true"
                        @end="drag = false"
                        v-model="taskbarButtons"
                        draggable=".item"
                        group="items"
                        @change="updateButtonsArr(side)"
                    >
                        <button
                            v-for="item in taskbarButtons"
                            :key="item.ToolID"
                            @click="taskbarButtonClick(item, side)"
                            :class="
                                projectOptionClass(
                                    item.ProjectDependent,
                                    item.ProjectList
                                )
                            "
                            :id="item.ToolID"
                            :disabled="
                                item.ProjectDependent &&
                                    projectNotSelected(item.ProjectList)
                            "
                            v-show="
                                !(
                                    item.ProjectDependent &&
                                    item.ProjectList === ''
                                )
                            "
                        >
                            <img
                                class="pb-1"
                                width="26"
                                :src="toolIconPath(item.ToolName)"
                            />
                            {{ item.ToolName }}
                        </button>
                    </draggable-tools>
                </b-col>
            </b-row>
        </b-container>
    </b-col>
</template>

<script>
import ResizeBar from './Sidebars/ResizeBar.vue';
import draggable from 'vuedraggable';
import {
    toolIconPath,
    getComponentName,
    getInitialPanelWidthtool,
} from '../../utilities/oneMapTools.js';
import { mapActions, mapState, mapGetters } from 'vuex';
import ProjectSideBar from '../projects/manage/ProjectSideBar.vue';
import { commonService } from '../../services';

export default {
    name: 'OnemapSidebar',
    components: {
        ResizeBar,
        ProjectSideBar,
        LayersWrapper: () => import('../EmergencyManagement/LayersWrapper.vue'),
        ProjectDatasets: () => import('../projects/ProjectDatasets.vue'),
        DrawSideBar: () => import('./DrawTool/DrawSideBar.vue'),
        MeasureSidebar: () => import('./MeasureTool/MeasureSidebar.vue'),
        'draggable-tools': draggable,
        IdentifySidebar: () => import('./IdentifyTool/IdentifySidebar.vue'),
        Weather: () => import('./Weather.vue'),
        CommonOperatingPicture: () =>
            import('./../EmergencyManagement/CommonOperatingPicture.vue'),
        DataPostSidebar: () =>
            import('../CommonComponents/DataPostingTools/DataPostSidebar.vue'),
        ContourSidebar: () =>
            import('../CommonComponents/DataPostingTools/ContourSidebar.vue'),
        DocumentManagement: () => import('../DocumentManagement.vue'),
        PowerBIReportsContainer: () => import('./PowerBIReportsContainer.vue'),
        'map3d-wrapper': () => import('./Map3DTool/Map3DWrapper.vue'),
        PrintMap: () => import('./PrintMap.vue'),
        SearchAndZoom: () => import('../EmergencyManagement/SearchandZoom.vue'),
        'idat-export-sidebar': () => import('../projects/iDatExport.vue'),
        DreamsWrapper: () => import('../Dreams/DreamsWrapper.vue'),
        RetrieveWork: () => import('../projects/RetrieveWork.vue'),
        DataCollectionSidebar: () =>
            import(
                '../CommonComponents/GISDataCollection/DataCollectionSidebar.vue'
            ),
        VectorPlanning: () =>
            import('../projects/layers/vectors/VectorPlanning.vue'),
        OU4SampleTracking: () => import('../projects/OU4SampleTracking.vue'),
    },
    props: {
        side: String,
        taskbarButtons: Array,
        toolToOpen: String,
    },
    data() {
        return {
            displayedTool: '',
            displayedToolID: 0,
            sidebarUpdatedWidth: 0,
            componentName: '',
        };
    },
    watch: {
        toolToOpen(newV) {
            if (newV) {
                this.openToolInOtherSide(newV);
            }
        },
        // sets the tool that we want to open, checks if this sidebar has that tool,
        //  if yes, opens sidebar, else, nothing
        openSidebarFlag(newVal) {
            if (newVal !== '') {
                if (
                    this.taskbarButtons.filter(
                        (button) => button.ToolName == newVal
                    ).length
                ) {
                    this.displayedTool = newVal;
                    this.activateToolHighlight(newVal);
                    this.$store.commit('oneMap/mutateOpenSidebarFlag', '');
                }
            }
        },
        // check if all sidebars should close
        closeSidebars(newVal) {
            if (newVal) {
                this.removeActiveButton('right');
                this.removeActiveButton('left');
                this.displayedTool = '';
                // need a very slight delay so that both sidebars trigger,
                //  otherwise the first one closes, but not the second
                setTimeout(() => {
                    this.$store.commit('oneMap/mutateCloseSidebars', false);
                }, 10);
            }
        },
        ProjectID(value) {
            if (!value) {
                this.removeActiveButton('right');
                this.removeActiveButton('left');
            }
        },
        dynamicSidebarWidth(newWidth) {
            this.$emit('toolChanged', {
                side: this.side,
                width: newWidth.width,
            });
        },
        displayedTool(newValue) {
            if (newValue) {
                this.componentName = getComponentName(this.displayedTool);
            }
            this.handleTool(newValue, this.side);
        },
        taskbarButtons(newValue) {
            if (this.$store.state.projects.selectedProjects.length < 1) {
                let item = this.taskbarButtons.find((item) => {
                    return item.ToolName === 'Projects';
                });
                this.taskbarButtonClick(item, item.Location);
            }
        },
    },
    mounted() {},
    methods: {
        ...mapActions('common', ['setTool']),
        toolIconPath,
        removeActiveButton(side) {
            const taskbarButtons = document.querySelectorAll(
                `.taskbar-buttons_${side} > div > .taskbar-button`
            );
            taskbarButtons.forEach((element) => {
                element.classList.remove('taskbar-button-active');
            });
        },
        taskbarButtonClick(item, side) {
            if (this.displayedTool) {
                this.removeActiveButton(side);
            }
            if (this.displayedTool == item.ToolName) {
                this.displayedTool = '';
                this.removeActiveButton(side);
            } else {
                if (item.iFrame) {
                    this.setTool(item.ToolName);
                    this.$router.push({
                        name: item.ToolName.replace(/\s/g, ''),
                        params: {
                            toolId: item.ToolID,
                            url: item.Url,
                        },
                    });
                } else {
                    this.displayedTool = item.ToolName;
                    this.displayedToolID = item.ToolID;
                    document
                        .getElementById(item.ToolID)
                        .classList.add('taskbar-button-active');
                }
            }
        },
        updateWidth(deltaX) {
            if (this.side == 'right') {
                this.sidebarUpdatedWidth -= deltaX;
            } else if (this.side == 'left') {
                this.sidebarUpdatedWidth += deltaX;
            }
        },
        updateButtonsArr(side) {
            this.taskbarButtons.forEach((element, index) => {
                element.location = side;
                element.SortOrder = index;
            });
            commonService
                .updateUserToolOption(this.taskbarButtons)
                .then((res) => {})
                .catch((err) => {});
        },
        //if selected projects contains valid project ids then return false
        projectNotSelected(validProjects) {
            let validProjectsArr = validProjects.split(',').map(Number);
            return !this.selectedProjectsArr.some((selected) => {
                if (selected && selected.ProjectID) {
                    return validProjectsArr.includes(selected.ProjectID);
                }
                return true;
            });
        },
        projectOptionClass(projectDependent, withProject) {
            if (projectDependent && withProject === '') return '';
            else
                return 'd-flex flex-column align-items-center item taskbar-button mx-auto py-2';
        },
        close() {
            this.displayedTool = '';
            this.removeActiveButton(this.side);
        },
        showTool(newToolID) {
            let tool = this.toolLevels[newToolID];
            this.displayedTool = tool.ToolName;
            this.displayedToolID = newToolID;
        },
        navigateToDataTool() {
            let tool = this.taskbarButtons.find(
                (tool) => tool.ToolName === 'Data'
            );
            if (tool) {
                this.taskbarButtonClick(tool, tool.Location);
                this.displayedTool = 'Data';
            } else {
                this.removeActiveButton(this.side);
                this.$emit('openTool', this.side);
            }
        },
        openToolInOtherSide(newSide) {
            if (newSide === this.side) {
                let tool = this.taskbarButtons.find(
                    (tool) => tool.ToolName === 'Data'
                );
                this.taskbarButtonClick(tool, tool.Location);
                this.displayedTool = 'Data';
                this.$emit('revertSideValue');
                this.setOpenOtherSideTool();
            }
        },
        ...mapActions('gridFilters', {
            setOpenOtherSideTool: 'setOpenOtherSideTool',
        }),
        handleTool(tool, side) {
            this.$store.dispatch('oneMap/updateTool', { tool, side });
        },
        activateToolHighlight(tool) {
            let toolID = this.taskbarButtons.find(
                (button) => button.ToolName == tool
            ).ToolID;
            document
                .getElementById(toolID)
                .classList.add('taskbar-button-active');
        },
    },
    computed: {
        ...mapState('oneMap', [
            'openSidebarFlag',
            'closeSidebars',
            'toolLevels',
            'isEditingPostedLayer',
        ]),
        dynamicSidebarWidth() {
            let toReturn = {};
            if (this.displayedTool) {
                if (
                    this.displayedTool == 'Layers' &&
                    this.isEditingPostedLayer
                ) {
                    toReturn = { width: '525px' };
                } else if (getInitialPanelWidthtool(this.displayedTool)) {
                    toReturn = {
                        width: `${getInitialPanelWidthtool(
                            this.displayedTool
                        )}vw`,
                    };
                }
            } else {
                toReturn = { width: '0' };
            }
            return toReturn;
        },
        // componentName() {
        //     return this.displayedTool
        //         ? getComponentName(this.displayedTool)
        //         : '';
        //     // TODO: might be a better idea to return a common component for components currently not available
        //     // ex: <component-not-available>
        // },
        currentProperties() {
            let toReturn = {};
            if (this.displayedTool === 'Data') {
                toReturn = { toolID: this.displayedToolID };
            }
            return toReturn;
        },
        ...mapState({
            selectedProjectsArr: function(state) {
                return state.projects.selectedProjects;
            },
        }),
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.frame {
    border: 1px lightgrey solid;
}

.frame-sidebar {
    min-width: 20vw;
    max-width: 65vw;
    opacity: 1;
    background: white 0% 0% no-repeat padding-box;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 65px);
}

.frame-buttonbar {
    max-width: 74px;
    border-radius: 0 !important;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    background-color: white;
}

.frame-buttonbar.left {
    padding-left: 15px !important;
}
.frame-buttonbar.right {
    padding-right: 15px !important;
}

.taskbar-button {
    font-size: 0.8rem !important;
    width: 59px;
    height: fit-content;
    border: 0 !important;
    left: 0;
    border-radius: 0 !important;
    margin: 0 0 0 0;
    /* Sidebar width should be adjusted */
    overflow: hidden;
}
.taskbar-button:disabled {
    background-color: buttonface;
}
.taskbar-button:disabled img {
    opacity: 0.25;
    color: black;
}
.taskbar-button:hover:not([disabled]) {
    background-color: rgb(12, 111, 161);
    color: white;
}
.taskbar-button:hover:not([disabled]) img {
    filter: invert(88%) sepia(91%) saturate(46%) hue-rotate(350deg)
        brightness(112%) contrast(97%);
}
.taskbar-button-active {
    background-color: #006395;
    color: white;
}
.taskbar-button-active img {
    filter: invert(88%) sepia(91%) saturate(46%) hue-rotate(350deg)
        brightness(112%) contrast(97%);
}
</style>
